<template>
  <div class="wrapper">
    <div class="head">
      <div class="head-title">你好，世界</div>
    </div>
    <div class="container">
      <div class="title">
        <span>{{ detail.title }}</span>
        <span>{{ detail.date }} <i>/</i> {{ detail.read }} min read</span>
      </div>
      <div class="content" v-highlight v-html="detail.content_html" />
    </div>
    <a class="copyright" target="_blank" href="https://beian.miit.gov.cn/">{{ copyright }}</a>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/utils/http'
import { Toast } from 'vant'
Vue.use(Toast)
export default {
  data() {
    return {
      id: this.$route.query.id, // 文章ID
      name: '首页',
      title: '首页',
      detail: [],
      copyright: ''
    }
  },
  created() {
    if (!this.id) {
      this.$router.push({ name: '404' })
    }
    else {
      this.getDetail()
    }
  },
  methods: {
    // 获取首页广告列表
		getDetail () {
      let params = {
        id: this.id
      }
			this.loading = true
      axios.get('http://adv.down5g.net/api/web/article_detail', {params: params}).then((res) => 
      {
        let code = res.code
        if (code != 1) {
          Toast('页面加载错误')
          return false
        } else {
          this.detail = res.data
          this.copyright = res.data.copyright
          console.log(res.data, 11111)
        }
      })
		}
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  display: block;
  width: 100%;
  background: #fff;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.86);
  line-height: 26px;

  // 头部
  .head {
    display: block;
    margin-bottom: 30px;
    background: url('~@/assets/images/bg.jpg') no-repeat center center;
    background-attachment: scroll;
    position: relative;
    background-size: cover;
    height: 330px;
    .head-title {
      display: flex;
      width: 100%;
      height: 360px;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 40px;
      font-weight: 800;
    }
  }
  .container {
    display: block;
    width: calc(100% - 40px);
    max-width: 750px;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 50px;
    min-height: calc(100vh - 390px);
  }
  .title {
    display: flex;
    flex-flow: column;
    span:nth-child(1) {
      font-size: 28px;
      font-weight: 700;
      letter-spacing: 1px;
      padding: 24px 0;
      color: #333;
    }
    span:nth-child(2) {
      color: #5E5E5E;
      font-size: 12px;
      padding-bottom: 24px;
      i {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.1);
        margin: 0 4px;
      }
    }
  }
}

/deep/ .hljs {
  margin: 10px 0;
  border-radius: 5px;
}


/deep/ .content {
  h1, h2, h3, h4 {
    margin: 16px 0;
    font-weight: 700;
    padding-top: 16px;
  }

  p {
    margin: 8px 0;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 21px;
  }
  h5 {
    font-size: 18px;
  }

  p code {
    line-height: initial;
    word-wrap: break-word;
    border-radius: 0;
    background-color: #fff5f5;
    color: #c53030;
    padding: 0.2em 0.33333333em;
    margin-left: 0.125em;
    margin-right: 0.125em;
  }

  blockquote {
    color: #9a9a9a;
    position: relative;
    padding: 0.4em 0 0 2.2em;
    font-size: 0.96em;
  }
  blockquote:before {
    position: absolute;
    top: -4px;
    left: 0;
    content: "\201c";
    font: 700 62px/1 serif;
    color: rgba(0, 0, 0, 0.1);
  }
}
.copyright {
    display: flex;
    width: calc(100% - 40px);
    max-width: 750px;
    margin: 0 auto;
    font-size: 14px;
    height: 60px;
    justify-content: center;
    align-items: center;
  }
</style>
